import React, { FC } from 'react';

import { FilterContextProvider } from 'bundles/contexts/FilterContext/FilterContextProvider';
import Component from './Component';
import { FilterHocInterface } from './types';

const withFilterPaginationCsv = (Table: FC<Record<string, any>>, FilterContent?: FC | null, HeaderActionsContent?: FC | null, TableHeaderTitle?: FC | null, AddCriteriaContent?: FC | null) => {
  const ComponentWrapper = (props: FilterHocInterface) =>
    <FilterContextProvider {...props}>
      <Component Table={Table} FilterContent={FilterContent} HeaderActionsContent={HeaderActionsContent} TableHeaderTitle={TableHeaderTitle} AddCriteriaContent={AddCriteriaContent} />
    </FilterContextProvider>;

  ComponentWrapper.displayName = 'ComponentWrapper';

  return ComponentWrapper;
};

export default withFilterPaginationCsv;
